export const LOGICAL_OPERATORS = Object.freeze([
  'And',
  'Or',
]);

export const DEFAULT_NEW_FILTER = Object.freeze({
  operator: null,
  options: {},
  operands: [],
});

export const DEFAULT_NEW_FILTER_GROUP = Object.freeze({
  operator: 'And',
  operands: [{
    operator: 'And',
    operands: [],
  }],
});
