import { floatifyNumber } from '../number';

export class ChangeMessageBuilder {
  static build(args) {
    const builder = new ChangeMessageBuilder(args);
    return builder.build();
  }

  constructor({ field, ledgerEntry, i18n }) {
    this.field = field;
    this.ledgerEntry = ledgerEntry;
    this.i18n = i18n;
    this.content = ledgerEntry.diff[field.id];
  }

  build() {
    return this[`build${this.field.type}ChangeMessage`]?.() || this.buildDefaultChangeMessage();
  }

  translate(...args) {
    return this.i18n.t(...args);
  }

  buildAttachmentChangeMessage() {
    if (this.ledgerEntry.type === 'Ledger::Record::FieldsUpdated') {
      if (!this.content) return this.translate('detached');
      return this.translate('changed', { changedTo: this.content.map(fileData => fileData.filename).join(', ') });
    }

    if (this.ledgerEntry.type === 'Ledger::Record::AttachmentAdded') return this.translate('attached', { changedTo: this.content.filename });
    if (this.ledgerEntry.type === 'Ledger::Record::AttachmentRemoved') return this.translate('detachedFile', { changedTo: this.content.filename });

    return null;
  }

  buildChecklistChangeMessage() {
    if (this.ledgerEntry.type === 'Ledger::Record::FieldsUpdated' && !this.content) return this.translate('checklistCleared');

    return this.translate('changed', { changedTo: this.content.display_name });
  }

  buildCoverImageChangeMessage() {
    if (this.ledgerEntry.type === 'Ledger::Record::FieldsUpdated') {
      if (!this.content) return this.translate('detached');
      return this.translate('changed', { changedTo: this.content.map(fileData => fileData.filename).join(', ') });
    }

    if (this.ledgerEntry.type === 'Ledger::Record::CoverImageAdded') return this.translate('attached', { changedTo: this.content.filename });
    if (this.ledgerEntry.type === 'Ledger::Record::CoverImageRemoved') return this.translate('detached');

    return null;
  }

  buildFormulaChangeMessage() {
    return this.translate('changed', { changedTo: this.content.value });
  }

  buildLinkChangeMessage() {
    if (this.ledgerEntry.type === 'Ledger::Record::FieldsUpdated') return this.translate('changed', { changedTo: this.content?.map(link => link.foreign_record_display_name).join(', ') });
    if (this.ledgerEntry.type === 'Ledger::Record::Linked') return this.translate('linkedTo', { linkedTo: this.content.foreign_record_display_name });
    if (this.ledgerEntry.type === 'Ledger::Record::Unlinked') return this.translate('unlinkedFrom', { unlinkedFrom: this.content.foreign_record_display_name });
    if (this.ledgerEntry.type === 'Ledger::Record::Created') return this.translate('changed', { changedTo: this.content.map(link => link.foreign_record_display_name).join(', ') });

    return null;
  }

  buildLookupChangeMessage() {
    if (Array.isArray(this.content)) {
      return this.translate('changed', {
        changedTo: this.content?.map((foreignRecordData) => {
          switch (this.field.options.lookup_field_type) {
            case 'Attachment':
            case 'CoverImage':
              return foreignRecordData.foreign_record_display_name.map(fileData => fileData.filename).join(', ');
            case 'Link':
              return foreignRecordData.foreign_record_display_name.map(link => link.foreign_record_display_name).join(', ');
            default:
              return foreignRecordData.foreign_record_display_name;
          }
        })?.join(', '),
      });
    }

    if (this.content) {
      switch (this.field.options.lookup_field_type) {
        case 'Attachment':
        case 'CoverImage':
          return this.translate('changed', { changedTo: this.content.foreign_record_display_name.map(fileData => fileData.filename).join(', ') });
        default:
          return this.translate('changed', { changedTo: this.content.foreign_record_display_name });
      }
    }

    return null;
  }

  buildNumberChangeMessage() {
    return this.translate('changed', { changedTo: floatifyNumber(this.content?.value, this.field.options.number_options.precision) });
  }

  buildSelectChangeMessage() {
    if (Array.isArray(this.content)) {
      return this.translate('changed', { changedTo: this.content.map(option => option.select_option_display_name).join(' | ') });
    }

    return this.translate('changed', { changedTo: this.content?.select_option_display_name });
  }

  buildMultipleSelectChangeMessage() {
    return this.buildSelectChangeMessage();
  }

  buildUserChangeMessage() {
    return this.translate('changed', { changedTo: this.content.username });
  }

  buildDefaultChangeMessage() {
    return this.translate('changed', { changedTo: this.content });
  }
}
