<i18n lang="yaml">
pt:
  data: 'Dados'
  exportCategoryAsAIContext: 'Exportar categoria como contexto para usar em IA'
  home: 'Loja de Apps'
  settings: 'Ajustes'
  views: 'Páginas'
  workflows: 'Automações'
en:
  data: 'Data'
  exportCategoryAsAIContext: 'Export category as context to use in AI'
  home: 'App Store'
  settings: 'Settings'
  views: 'Pages'
  workflows: 'Automations'
</i18n>

<template>
  <div>
    <deck-button-group
      v-if="$auth.loggedIn && $auth.user.role === 'admin'"
      class="studio-topbar__pages-menu"
      :value="currentValue"
      :buttons="builderItems"
      buttons-kind="ghost"
      color="controls"
      kind="radio"
      mandatory
    />
    <deck-button
      v-if="showExportCategoryAsYaml"
      kind="ghost"
      color="controls"
      icon="download"
      is-ready
      :text="$t('exportCategoryAsAIContext')"
      :tooltip-props="{ position: 'bottom', instant: true }"
      :loading="exporting"
      @click="exportCategoryAsAIContext"
    />
  </div>
</template>

<script>
import { streamDownload } from '~/assets/javascript/utils';
import { mapGetters } from 'vuex';
import DeckButton from '~/deck/button';
import DeckButtonGroup from '~/deck/button-group';

export default {
  name: 'StudioTopbarPagesMenu',
  components: {
    DeckButton,
    DeckButtonGroup,
  },
  props: {
    // eslint-disable-next-line vue/no-unused-properties
    transparent: { type: Boolean, default: false },
  },
  data() {
    return {
      exporting: false,
    };
  },
  computed: {
    ...mapGetters('workspace', ['tenantSlug', 'currentCategory', 'viewsOrder', 'workflowsOrder']),
    ...mapGetters('features', ['isFeatureEnabled']),
    builderItems() {
      const items = [
        { value: 't-tenantSlug-studio-data', icon: 'database', text: this.$t('data') },
        { value: 't-tenantSlug-studio-pages-id', icon: 'browser', text: this.$t('views') },
        { value: 't-tenantSlug-studio-automations-id', icon: 'bolt', text: this.$t('workflows') },
        { value: 't-tenantSlug-studio-settings', icon: 'gear', text: this.$t('settings') },
      ];

      return items.map(item => ({
        ...item,
        to: this.localePath({ name: item.value, params: { tenantSlug: this.tenantSlug } }),
        tooltipProps: {
          position: 'bottom',
          instant: true,
        },
      }));
    },
    currentValue() {
      return this.builderItems.find(item => this.$route.name.includes(item.value))?.value;
    },
    showExportCategoryAsYaml() {
      return this.isFeatureEnabled('exportCategoryAsAIContext') && ['t-tenantSlug-studio-automations-id', 't-tenantSlug-studio-pages-id'].includes(this.currentValue);
    },
  },
  methods: {
    async exportCategoryAsAIContext() {
      if (this.exporting) return;

      try {
        this.exporting = true;
        const { data } = await this.$api.$post('/builder/apps', {
          app: this.currentCategory.category_name,
          icon: this.currentCategory.category_icon,
          category: this.currentCategory.category_system_name || 'home',
          solutions: ['peopleops'],
          categories: ['company'],
          description: this.currentCategory.category_name,
          views: this.extractIds(this.viewsOrder),
          workflows: this.extractIds(this.workflowsOrder),
          template_format: 'ai',
        });

        streamDownload(this.currentCategory.category_name, 'json', data);
      } catch (error) {
        this.$errorRescue(this, error, 'exportingCategoryAsAIContext');
      } finally {
        this.exporting = false;
      }
    },
    extractIds(resourcesOrder) {
      return resourcesOrder.map((item) => {
        if (item.id) return item.id;
        return this.extractIds(item.items);
      }).flat();
    },
  },
};
</script>

<style lang="scss">
.studio-topbar__pages-menu .v-slide-group__content {
    flex-wrap: wrap;
    justify-content: center;
  }
</style>
