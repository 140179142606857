/* eslint-disable consistent-return */
import Vue from 'vue';
import { camelCase, isEqual, isEmpty, merge, omit, omitBy, cloneDeep } from 'lodash';
import { DYNAMIC_DATES, DYNAMIC_RECORDS_TEMPLATE_LINK_VALUES } from '~/assets/javascript/constants';
import {
  fetchFieldsIdsFromInfoComponent,
  fetchMainContentInfoComponentById,
  formatDateDisplayName,
  transformDynamicDate,
  transformDraftRecordData,
  transformRawRecordData,
  buildValuesPatchPayload,
  createDeconstructedPromise,
} from '~/assets/javascript/utils';

const loadRecordsByView = (state, { records, subviews, fields_ids: fieldsIds }) => {
  const newRecords = (records || []).reduce((acc, record) => {
    // force field data not present to be undefined
    // it is needed to avoid having stale data in the store
    const recordData = fieldsIds.reduce((acc, fieldId) => {
      acc[fieldId] = record.data[fieldId];
      return acc;
    }, {});

    acc.draftRecordsById[record.id] = {
      id: record.id,
      ...state.draftRecordsById[record.id],
      ...omitBy(recordData, (_value, fieldId) => state.draftRecordFieldsChangedMapping[record.id]?.[fieldId] === 'changed'),
    };

    if (!isEmpty(state.rawRecordsById[record.id])) {
      acc.rawRecordsById[record.id] = acc.draftRecordsById[record.id];
    }

    return acc;
  }, {
    draftRecordsById: {},
    rawRecordsById: {},
  });

  if (!isEmpty(newRecords.draftRecordsById)) {
    state.draftRecordsById = {
      ...state.draftRecordsById,
      ...newRecords.draftRecordsById,
    };
  }

  if (!isEmpty(newRecords.rawRecordsById)) {
    state.rawRecordsById = {
      ...state.rawRecordsById,
      ...newRecords.rawRecordsById,
    };
  }

  subviews?.forEach((subview) => {
    loadRecordsByView(state, subview);
  });
};

export const state = () => ({
  rawRecordsById: {},
  draftRecordsById: {},
  viewFiltersByViewIdAndRecordId: {},
  requiredFieldsByViewIdAndRecordId: {},
  draftRecordFieldsChangedMapping: {},
  draftRecordsByIdCopy: {},
  isSaving: false,
  hasNewRecord: false,
});

export const getters = {
  rawRecordById: state => id => state.rawRecordsById[id],
  draftRecordById: state => id => state.draftRecordsById[id],
  viewFiltersByViewIdAndRecordId: state => (viewId, recordId) => state.viewFiltersByViewIdAndRecordId[`${viewId}-${recordId}`] || [],
  hasChangesInDraftRecords: state => Object.keys(state.draftRecordFieldsChangedMapping).length > 0,
  draftRecordFieldsChangedById: state => id => !isEmpty(state.draftRecordFieldsChangedMapping[id]),
};

export const mutations = {
  setIsSaving(state, isSaving) {
    state.isSaving = isSaving;

    state.draftRecordFieldsChangedMapping = Object.keys(state.draftRecordFieldsChangedMapping).reduce((acc, recordId) => {
      acc[recordId] = Object.keys(state.draftRecordFieldsChangedMapping[recordId]).reduce((acc, fieldId) => {
        // There are only 3 possibilities: 'changed', 'saving', and null.
        // If it is null, no action is needed.
        // If it is 'changed':
        // - If isSaving is true, it means the data is being saved to the database, so change it to 'saving'.
        // - If isSaving is false, it means there was a value change during the save operation and the data is now different from what was saved, so keep it as 'changed'.
        // If it is 'saving':
        // - If isSaving is true, it means another save operation is starting for the same data. In practice, this should not happen because we use debounce.
        // - If isSaving is false, it means something happened during the save and it cannot be guaranteed if the save was successful, so revert the data state to 'changed'.
        acc[fieldId] = isSaving ? 'saving' : 'changed';
        return acc;
      }, {});

      return acc;
    }, {});
  },
  updateDraftRecordFieldValue(state, { recordId, fieldId, value }) {
    state.rawRecordsById[recordId] ||= state.draftRecordsById[recordId];

    Vue.set(state.draftRecordsById, recordId, {
      ...state.draftRecordsById[recordId],
      [fieldId]: value,
    });

    // Updates the draft record fields changed mapping
    if (state.draftRecordFieldsChangedMapping[recordId] && isEqual(state.rawRecordsById[recordId][fieldId], value)) {
      // We don't need to set the field as changed if the value is the same as the raw record
      // In that case, we remove the field from the mapping
      // This is to avoid accounting for fields that were changed and then changed back to the original value
      // Ex: User changes a field, then changes it back to the original value
      // In this case, the field should not be accounted as changed
      // So we remove it from the mapping
      Vue.delete(state.draftRecordFieldsChangedMapping[recordId], fieldId);

      if (isEmpty(state.draftRecordFieldsChangedMapping[recordId])) {
        Vue.delete(state.draftRecordFieldsChangedMapping, recordId);
      }
    } else {
      // If the value is different from the raw record, it adds the field to the mapping
      Vue.set(state.draftRecordFieldsChangedMapping, recordId, {
        ...state.draftRecordFieldsChangedMapping[recordId],
        [fieldId]: 'changed',
      });
    }
  },
  createDraftRecordsByIdCopy(state) {
    // Use the rawRecordsById as reference to create a copy of the draft records
    // the rawRecordsById just has the modified records reference, so it reduces the copied data
    state.draftRecordsByIdCopy = Object.fromEntries(Object.keys(state.rawRecordsById).map(recordId => [
      recordId,
      cloneDeep(state.draftRecordsById[recordId]),
    ]));
  },
  clearDraftRecordsByIdCopy(state) {
    state.draftRecordsByIdCopy = {};
  },
  persistDraftRecordData(state) {
    // use the draft records copy to update the raw records
    // to ensure the data that was sent to endpoint is the same as the one in the store
    state.rawRecordsById = {
      ...state.rawRecordsById,
      ...state.draftRecordsByIdCopy,
    };

    state.draftRecordsByIdCopy = {};
  },
  resetDraftRecordFieldsChangedMapping(state) {
    state.draftRecordFieldsChangedMapping = Object.keys(state.draftRecordFieldsChangedMapping).reduce((acc, recordId) => {
      const recordResult = Object.keys(state.draftRecordFieldsChangedMapping[recordId]).reduce((acc, fieldId) => {
        if (state.draftRecordFieldsChangedMapping[recordId][fieldId] === 'changed') {
          acc[fieldId] = 'changed';
        }

        return acc;
      }, {});

      if (!isEmpty(recordResult)) {
        acc[recordId] = recordResult;
      }

      return acc;
    }, {});
  },
  loadRecordsByView(state, view) {
    loadRecordsByView(state, view);
  },
  updateViewFilters(state, { viewId, recordId, viewFilters }) {
    Vue.set(state.viewFiltersByViewIdAndRecordId, `${viewId}-${recordId}`, viewFilters);
  },
  updateRequiredFields(state, { viewId, recordId, requiredFieldsIds }) {
    Vue.set(state.requiredFieldsByViewIdAndRecordId, `${viewId}-${recordId}`, requiredFieldsIds);
  },
  buildNewRecord(state, {
    vueContext: {
      newRecordId,
      view: {
        fields,
        records_template: recordsTemplate = [],
        fields_format_options: fieldsFormatOptions,
      },
      initialValues = {},
      $auth: { user },
      $i18n: { locale },
      $route: { query: { sri: sourceRecordId } = {} },
    },
    linkOptionsByFieldId = {},
  }) {
    const computedInitialValues = merge(
      recordsTemplate.reduce((acc, { field_id: fieldId, value }) => {
        const field = fields.find(({ id }) => id === fieldId);

        if (!field) return acc; // TODO: Don't send records_template data that is not present in view

        let valueToSet = value;

        switch (field.type) {
          case 'Link': {
            const foreignRecordIds = [];

            if (value === 'current_user') {
              if (user.guest_record_id) foreignRecordIds.push(user.guest_record_id);
              if (user.people_record_id) foreignRecordIds.push(user.people_record_id);
            } else if (value === 'source_record') {
              if (sourceRecordId && !field.uneditable) foreignRecordIds.push(sourceRecordId);
            }

            if (foreignRecordIds.length > 0) {
              const foreignRecord = (linkOptionsByFieldId[field.id] || []).find(({ record_id: recordId }) => foreignRecordIds.includes(recordId));

              if (foreignRecord) {
                valueToSet = [{
                  foreign_record_id: foreignRecord.record_id,
                  foreign_record_display_name: foreignRecord.record_display_name,
                }];

                if (foreignRecord.record_data) {
                  [
                    state.draftRecordsById,
                    state.rawRecordsById,
                  ].forEach((stateContent) => {
                    Vue.set(stateContent, foreignRecord.record_id, {
                      id: foreignRecord.record_id,
                      ...foreignRecord.record_data,
                    });
                  });
                }
              } else {
                valueToSet = [];
              }
            } else {
              valueToSet = [];
            }

            break;
          }

          case 'Date':
          case 'DateTime':
            if (DYNAMIC_DATES.map(({ key }) => key).includes(value)) {
              const date = transformDynamicDate(value);

              valueToSet = {
                value: date,
                display_name: formatDateDisplayName(date, {
                  fieldId,
                  fieldsFormatOptions,
                  locale,
                }),
              };
            }

            break;
          case 'Select':
          case 'MultipleSelect':
            valueToSet = (value || []).map((optionId) => {
              const selectOption = field.options.select_options.find(({ id }) => id === optionId);

              if (!selectOption) return null;

              return {
                select_option_color: selectOption.color,
                select_option_display_name: selectOption.value,
                select_option_id: selectOption.id,
                select_option_value: selectOption.internal_value,
              };
            }).filter(Boolean);

            break;
          default:
            break;
        }

        return ({
          ...acc,
          [fieldId]: valueToSet,
        });
      }, {}),
      initialValues,
    );

    [
      state.draftRecordsById,
      state.rawRecordsById,
    ].forEach((stateContent) => {
      Vue.set(stateContent, newRecordId, {
        id: newRecordId,
        ...computedInitialValues,
        isNew: true,
      });
    });

    state.hasNewRecord = true;
  },
  resetRecords(state) {
    // the new records will be kept because it is used in the new page state
    // when these records are from subviews, they will be staled and not referenced by any record,
    // so they will be removed when the view is reloaded
    state.draftRecordsById = Object.fromEntries(Object.entries(state.draftRecordsById).map(([recordId, record]) => [
      recordId,
      state.rawRecordsById[recordId] || record, // if it has no rawRecord, it was not changed and don't need to be reset
    ]));

    state.draftRecordFieldsChangedMapping = {};
  },
  updateRecord(state, { recordId, recordData }) {
    Vue.delete(state.rawRecordsById, recordId);

    Vue.set(state.draftRecordsById, recordId, {
      id: recordId,
      ...recordData,
      ...state.draftRecordsById[recordId],
    });
  },
  // It is used for minicards that will load data to be used to check if it has changes
  saveForeignRecord(state, { recordId, recordData }) {
    // Set the current state after the new data because it will contain fresh data and can be interpreted as changed wrongly
    // Causing save permission errors when it cannot be changed by the user
    Vue.set(state.rawRecordsById, recordId, {
      id: recordId,
      ...recordData,
      ...state.rawRecordsById[recordId],
    });

    Vue.set(state.draftRecordsById, recordId, {
      id: recordId,
      ...recordData,
      ...state.draftRecordsById[recordId], // set the current state after the new data because it may contain changes
    });
  },
  reset(state) {
    state.rawRecordsById = {};

    // delete all draft records except the ones that are new
    state.draftRecordsById = Object.values(state.draftRecordsById).reduce((acc, record) => {
      if (record.isNew) acc[record.id] = record;
      return acc;
    }, {});

    state.draftRecordFieldsChangedMapping = {};
  },
  resetViewFiltersAndRequiredFields(state) {
    state.viewFiltersByViewIdAndRecordId = {};
    state.requiredFieldsByViewIdAndRecordId = {};
  },
  commitRecordCreated(state, { recordId, recordData }) {
    Vue.set(state.rawRecordsById, recordId, {
      id: recordId,
      ...recordData,
    });

    Vue.set(state.draftRecordsById, recordId, {
      id: recordId,
      ...recordData,
    });
  },
  markNewRecordFlag(state, flag) {
    state.hasNewRecord = flag;
  },
};

export const actions = {
  async saveRecordById({ state, dispatch }, { recordId, view }) {
    const draftRecord = state.draftRecordsById[recordId];

    if (draftRecord.isNew) {
      return dispatch('createRecordById', { recordId, view });
    }

    return dispatch('updateRecordById', { recordId, view });
  },
  async updateRecordById(
    { getters, rootGetters, commit, dispatch, state },
    {
      recordId,
      view,
    },
  ) {
    const {
      id: viewId,
      fields,
      permit_record_update: permitRecordUpdate,
      page_type: pageType,
      page_data_default: pageDataDefault,
    } = view;

    if (!permitRecordUpdate && !pageDataDefault) {
      this.$rollbar.error('Update record in view without permit_record_update attempt');
      return;
    }

    commit('setIsSaving', true);

    const loadId = `view__${viewId}`;

    const {
      promise,
      resolve,
      reject,
    } = createDeconstructedPromise();

    const saveMethod = () => {
      // create the payload on demand because it just send the modified data
      // if it is created before, it can send wrong data
      // because the request could not be finished yet
      // and until it is finished, the raw record will not be updated
      // and the user can change back some value to the original state
      // and it will be ignored since the payload was created before
      const rawRecord = transformRawRecordData(getters.rawRecordById(recordId), fields, viewId, rootGetters);
      // make a copy to ensure that the user will not change the draft record while the request is being made
      commit('createDraftRecordsByIdCopy');
      const draftRecord = transformDraftRecordData(state.draftRecordsByIdCopy[recordId], fields, viewId, rootGetters);

      if (isEqual(rawRecord, draftRecord)) {
        commit('clearDraftRecordsByIdCopy');
        return Promise.resolve();
      }

      const params = {
        values: buildValuesPatchPayload(rawRecord, draftRecord, view),
      };

      if (!pageDataDefault) params.view_id = viewId;

      return this.$api.$patch(`/records/${recordId}`, params);
    };

    const onSave = async (result) => {
      // result will not be present when the save request has been canceled due to it has no changes
      if (!result) {
        commit('setIsSaving', false);
        resolve({ id: recordId });
        return;
      }

      window.analytics.track(`${camelCase(pageType)}Submission`, { method: 'update' });

      // As it change the store data we need to wait for all the pending saves to be finished
      if (this.$asyncDataManager.hasPendingSave(loadId)) {
        resolve({ id: recordId, requestId: result.request_id });
        return;
      }

      commit('resetDraftRecordFieldsChangedMapping');
      // Update staled data in the store
      commit('persistDraftRecordData');

      // We need to test the view filters again to check if the record still matches, because a sync workflow
      // could have changed the record data in the backend.
      if (view.has_sync_workflow) {
        await dispatch('updateViewFilters', { viewId, recordId, fields });
      }

      commit('setIsSaving', false);

      resolve({ id: recordId, requestId: result.request_id });
    };

    const onSaveError = (error) => {
      commit('clearDraftRecordsByIdCopy');
      commit('setIsSaving', false);
      reject(error);
    };

    const loadMethod = async () => {
      if (pageDataDefault || !state.hasNewRecord) return Promise.resolve();

      commit('markNewRecordFlag', false);

      return dispatch('view/getViewData', {
        recordId,
        viewId,
      }, { root: true });
    };

    const onLoad = (data) => {
      if (!data) return;

      return dispatch('view/applyViewData', {
        data,
        viewId,
        refresh: true,
      }, { root: true });
    };

    const asyncPromise = this.$asyncDataManager.save({
      saveMethod,
      onSave,
      onSaveError,
      loadMethod,
      onLoad,
      saveId: `record__${recordId}`,
      loadId,
    });

    // this is a general promise used just to ensure that all save from the same record will be resolved
    // this means that it can be resolved for another save call, due to the debounce behavior
    asyncPromise.then(() => resolve({ id: recordId }));

    return promise;
  },
  async createRecordById(
    { getters, rootGetters, commit },
    {
      recordId,
      view,
    },
  ) {
    const {
      fields,
      id: viewId,
      permit_record_insert: permitRecordInsert,
      page_type: pageType,
      page_data_default: pageDataDefault,

    } = view;
    if (!permitRecordInsert && !pageDataDefault) throw new Error('Insert record in view without permit_record_insert attempt');

    const draftRecord = transformDraftRecordData(getters.draftRecordById(recordId), fields, viewId, rootGetters);

    const params = {
      values: omit(draftRecord, ['id']),
    };

    if (!pageDataDefault) params.view_id = viewId;

    try {
      commit('setIsSaving', true);
      const result = await this.$api.$post('/records', params);
      window.analytics.track(`${camelCase(pageType)}Submission`, { method: 'insert' });

      commit('commitRecordCreated', {
        recordId: result.id,
        recordData: result.data,
      });

      commit('resetDraftRecordFieldsChangedMapping');
      commit('setIsSaving', false);

      return { id: result.id, requestId: result.request_id };
    } catch (error) {
      commit('setIsSaving', false);
      throw error;
    }
  },
  async selectExistentForeignRecord({ commit, dispatch }, {
    recordId,
    recordData,
    subview: { subviews },
  }) {
    const formattedRecordData = { ...recordData };

    await Promise.all(subviews.map(async (subview) => {
      const subviewFromFieldId = subview.metadata.from_field_id;

      // The record data may not have linked records for the subview
      if (!formattedRecordData[subviewFromFieldId]) return;

      await Promise.all(formattedRecordData[subviewFromFieldId].map(linkData => dispatch('selectExistentForeignRecord', {
        recordId: linkData.record_id,
        recordData: linkData.record_data,
        subview,
      })));

      formattedRecordData[subviewFromFieldId] = formattedRecordData[subviewFromFieldId].map(linkData => ({
        foreign_record_id: linkData.record_id,
        foreign_record_display_name: linkData.record_display_name,
        foreign_record_color: linkData.record_color,
      }));
    }));

    commit('saveForeignRecord', { recordId, recordData: formattedRecordData });
  },
  async buildNewRecord({ commit }, vueContext) {
    const {
      view: {
        id: viewId,
        records_template: recordsTemplate = [],
        fields,
      },
      $apiClient,
      $errorRescue,
    } = vueContext;

    const linkOptionsByFieldId = {};

    const populateLinkOptionsPromises = recordsTemplate.reduce((acc, { field_id: fieldId, value }) => {
      const field = fields.find(({ id }) => id === fieldId);

      if (field && field.type === 'Link' && DYNAMIC_RECORDS_TEMPLATE_LINK_VALUES.includes(value)) {
        const apiCall = value === 'current_user'
          ? $apiClient.views.foreignRecords.userLink.get
          : $apiClient.views.foreignRecords.get;

        // TODO: Skip link request is source record is not present
        acc.push((async () => {
          try {
            const { linked_records: linkOptions } = await apiCall(viewId, { fieldId, recordId: null });
            linkOptionsByFieldId[fieldId] = linkOptions;
          } catch (error) {
            $errorRescue(vueContext, error, 'buildNewRecord - populateLinkOptionsPromises', { onlyReturnErrorMsg: true });
            linkOptionsByFieldId[fieldId] = [];
          }
        })());
      }

      return acc;
    }, []);

    await Promise.all(populateLinkOptionsPromises);

    return commit('buildNewRecord', {
      vueContext,
      linkOptionsByFieldId,
    });
  },
  async updateViewFilters({ commit, state, rootGetters }, { viewId, recordId, fields }) {
    const draftRecord = state.draftRecordsById[recordId];

    if (!draftRecord) return;

    const record = {
      ...draftRecord,
      ...transformDraftRecordData(draftRecord, fields, viewId, rootGetters),
    };

    const { view_filters: viewFilters, required_fields_ids: requiredFieldsIds } = await this.$api.$post(`/views/${viewId}/view_filters/test`, {
      view_filter_type: 'InfoComponent',
      fields_data: omit(record, ['isNew', 'id']),
      record_id: record.isNew ? null : recordId,
    });

    commit('updateRequiredFields', {
      viewId,
      recordId,
      requiredFieldsIds,
    });

    commit('updateViewFilters', {
      viewId,
      recordId,
      viewFilters,
    });
  },
  async clearFilteredRecordData({ commit, state }, { view, recordId, previousFilters }) {
    if (!previousFilters || isEmpty(previousFilters)) return;

    const rawRecord = state.rawRecordsById[recordId];

    const viewFilters = state.viewFiltersByViewIdAndRecordId[`${view.id}-${recordId}`] || [];
    const previousViewFilterReferenceIds = previousFilters.map(filter => filter.info_component_id);
    const newViewFilterReferenceIds = new Set(viewFilters.map(filter => filter.info_component_id));
    const filteredComponentIds = previousViewFilterReferenceIds.filter(id => !newViewFilterReferenceIds.has(id));

    let fieldIdsToClear = new Set();

    filteredComponentIds.forEach((id) => {
      const infoComponent = fetchMainContentInfoComponentById(view, id);
      if (!infoComponent) return;
      const allowedTypes = ['field', 'group']; // Do not clear information from fields used in charts (read-only)
      const fieldIds = fetchFieldsIdsFromInfoComponent(infoComponent, allowedTypes);
      fieldIdsToClear = new Set([...fieldIdsToClear, ...fieldIds]);
    });

    fieldIdsToClear.forEach((fieldId) => {
      commit('updateDraftRecordFieldValue', {
        recordId,
        fieldId,
        value: rawRecord[fieldId],
      });
    });
  },
  async fetchRecordById({ commit }, recordId) {
    const { data: recordData } = await this.$apiClient.records.get(recordId);

    commit('updateRecord', {
      recordId,
      recordData,
    });
  },
};
