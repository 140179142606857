import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _62d18898 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _c1043de4 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _62c94f2b = () => interopDefault(import('../pages/magic-link/index.vue' /* webpackChunkName: "pages/magic-link/index" */))
const _9aa742ac = () => interopDefault(import('../pages/reset-password/index.vue' /* webpackChunkName: "pages/reset-password/index" */))

const _2a3955cc = () => interopDefault(import('../pages/set-workspace.vue' /* webpackChunkName: "pages/set-workspace" */))
const _53b02150 = () => interopDefault(import('../pages/sign-up/index.vue' /* webpackChunkName: "pages/sign-up/index" */))
const _09c116f4 = () => interopDefault(import('../pages/store/index.vue' /* webpackChunkName: "pages/store/index" */))
const _225d61be = () => interopDefault(import('../pages/store/apps/_appName.vue' /* webpackChunkName: "pages/store/apps/_appName" */))
const _6e40da9e = () => interopDefault(import('../pages/t/_tenantSlug/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/index" */))
const _67ef5831 = () => interopDefault(import('../pages/t/_tenantSlug/email-validation/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/email-validation/index" */))
const _e129af10 = () => interopDefault(import('../pages/t/_tenantSlug/login/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/login/index" */))
const _41e10308 = () => interopDefault(import('../pages/t/_tenantSlug/m/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/index" */))
const _c484d282 = () => interopDefault(import('../pages/t/_tenantSlug/p/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/index" */))
const _33076c86 = () => interopDefault(import('../pages/t/_tenantSlug/reset-password.vue' /* webpackChunkName: "pages/t/_tenantSlug/reset-password" */))
const _052d938c = () => interopDefault(import('../pages/t/_tenantSlug/sign-up/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/sign-up/index" */))
const _e40fba86 = () => interopDefault(import('../pages/t/_tenantSlug/studio/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/index" */))
const _a558a600 = () => interopDefault(import('../pages/t/_tenantSlug/m/account-configuration.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/account-configuration" */))
const _6d97ecd8 = () => interopDefault(import('../pages/t/_tenantSlug/sso/saml/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/sso/saml/index" */))
const _1d0f05d2 = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/index" */))
const _2de36008 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings" */))
const _08d9c08b = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/index" */))
const _dce7ce2a = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/app-builder/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/app-builder/index" */))
const _1139615c = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/authentication/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/authentication/index" */))
const _0f103230 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/global-parameters-manager/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/global-parameters-manager/index" */))
const _b4749e0e = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/integrations/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/integrations/index" */))
const _6d5ddc97 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/internal/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/internal/index" */))
const _6d75461b = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/system-entities/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/system-entities/index" */))
const _b1b1c218 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/tokens/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/tokens/index" */))
const _226c735c = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/index" */))
const _75f166f8 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/index" */))
const _f7e1ebc0 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/new.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/new" */))
const _1a64c4be = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/integrations/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/integrations/_id" */))
const _7059353a = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_id" */))
const _33567248 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_user-group-form/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_user-group-form/index" */))
const _fd462c98 = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/user-groups/_user-group-list-item/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/user-groups/_user-group-list-item/index" */))
const _61bae22d = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/_active-members-card/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/_active-members-card/index" */))
const _7f5aed2f = () => interopDefault(import('../pages/t/_tenantSlug/studio/settings/users/_users-data-table-card/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/settings/users/_users-data-table-card/index" */))
const _4ab77b12 = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id" */))
const _e02827d6 = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id/index" */))
const _487d3817 = () => interopDefault(import('../pages/t/_tenantSlug/studio/automations/_id/history.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/automations/_id/history" */))
const _73263086 = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/_id/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/_id/index" */))
const _49267eda = () => interopDefault(import('../pages/t/_tenantSlug/studio/pages/_id.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/pages/_id" */))
const _5bf4b0ca = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/_id/import.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/_id/import" */))
const _7ae9d674 = () => interopDefault(import('../pages/t/_tenantSlug/studio/data/_id/schema.vue' /* webpackChunkName: "pages/t/_tenantSlug/studio/data/_id/schema" */))
const _74e9da2c = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/index" */))
const _442e0122 = () => interopDefault(import('../pages/t/_tenantSlug/p/_viewId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/_viewId/index" */))
const _2bd7ef5d = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/r/new.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/r/new" */))
const _0adb77ab = () => interopDefault(import('../pages/t/_tenantSlug/m/_viewId/r/_recordId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/m/_viewId/r/_recordId/index" */))
const _4995d06e = () => interopDefault(import('../pages/t/_tenantSlug/p/_viewId/r/_recordId/index.vue' /* webpackChunkName: "pages/t/_tenantSlug/p/_viewId/r/_recordId/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _62d18898,
    name: "index___en"
  }, {
    path: "/login",
    component: _c1043de4,
    name: "login___pt"
  }, {
    path: "/magic-link",
    component: _62c94f2b,
    name: "magic-link___pt"
  }, {
    path: "/reset-password",
    component: _9aa742ac,
    name: "reset-password___pt"
  }, {
    path: "/reset-password",
    redirect: {"name":"set-tenant___pt"}
  }, {
    path: "/set-workspace",
    component: _2a3955cc,
    name: "set-workspace___pt"
  }, {
    path: "/sign-up",
    component: _53b02150,
    name: "sign-up___pt"
  }, {
    path: "/store",
    component: _09c116f4,
    name: "store___pt"
  }, {
    path: "/builder/views",
    redirect: {"name":"studio-pages-id___pt"}
  }, {
    path: "/en/login",
    component: _c1043de4,
    name: "login___en"
  }, {
    path: "/en/magic-link",
    component: _62c94f2b,
    name: "magic-link___en"
  }, {
    path: "/en/reset-password",
    component: _9aa742ac,
    name: "reset-password___en"
  }, {
    path: "/en/reset-password",
    redirect: {"name":"set-tenant___en"}
  }, {
    path: "/en/set-workspace",
    component: _2a3955cc,
    name: "set-workspace___en"
  }, {
    path: "/en/sign-up",
    component: _53b02150,
    name: "sign-up___en"
  }, {
    path: "/en/store",
    component: _09c116f4,
    name: "store___en"
  }, {
    path: "/en/builder/views",
    redirect: {"name":"studio-pages-id___en"}
  }, {
    path: "/studio/settings/integrations",
    redirect: {"name":"t-tenantSlug-studio-settings-integrations___pt"}
  }, {
    path: "/en/studio/settings/integrations",
    redirect: {"name":"t-tenantSlug-studio-settings-integrations___en"}
  }, {
    path: "/",
    component: _62d18898,
    name: "index___pt"
  }, {
    path: "/en/builder/workflows/:id?",
    redirect: {"name":"studio-automations-id___en"}
  }, {
    path: "/en/store/apps/:appName?",
    component: _225d61be,
    name: "store-apps-appName___en"
  }, {
    path: "/builder/workflows/:id?",
    redirect: {"name":"studio-automations-id___pt"}
  }, {
    path: "/en/t/:tenantSlug",
    component: _6e40da9e,
    name: "t-tenantSlug___en"
  }, {
    path: "/store/apps/:appName?",
    component: _225d61be,
    name: "store-apps-appName___pt"
  }, {
    path: "/en/t/:tenantSlug?/email-validation",
    component: _67ef5831,
    name: "t-tenantSlug-email-validation___en"
  }, {
    path: "/en/t/:tenantSlug?/login",
    component: _e129af10,
    name: "t-tenantSlug-login___en"
  }, {
    path: "/en/t/:tenantSlug?/m",
    component: _41e10308,
    name: "t-tenantSlug-m___en"
  }, {
    path: "/en/t/:tenantSlug?/p",
    component: _c484d282,
    name: "t-tenantSlug-p___en"
  }, {
    path: "/en/t/:tenantSlug?/reset-password",
    component: _33076c86,
    name: "t-tenantSlug-reset-password___en"
  }, {
    path: "/en/t/:tenantSlug?/sign-up",
    component: _052d938c,
    name: "t-tenantSlug-sign-up___en"
  }, {
    path: "/en/t/:tenantSlug?/studio",
    component: _e40fba86,
    name: "t-tenantSlug-studio___en"
  }, {
    path: "/en/t/:tenantSlug?/m/account-configuration",
    component: _a558a600,
    name: "t-tenantSlug-m-account-configuration___en"
  }, {
    path: "/en/t/:tenantSlug?/sso/saml",
    component: _6d97ecd8,
    name: "t-tenantSlug-sso-saml___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/data",
    component: _1d0f05d2,
    name: "t-tenantSlug-studio-data___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/settings",
    component: _2de36008,
    children: [{
      path: "",
      component: _08d9c08b,
      name: "t-tenantSlug-studio-settings___en"
    }, {
      path: "app-builder",
      component: _dce7ce2a,
      name: "t-tenantSlug-studio-settings-app-builder___en"
    }, {
      path: "authentication",
      component: _1139615c,
      name: "t-tenantSlug-studio-settings-authentication___en"
    }, {
      path: "global-parameters-manager",
      component: _0f103230,
      name: "t-tenantSlug-studio-settings-global-parameters-manager___en"
    }, {
      path: "integrations",
      component: _b4749e0e,
      name: "t-tenantSlug-studio-settings-integrations___en"
    }, {
      path: "internal",
      component: _6d5ddc97,
      name: "t-tenantSlug-studio-settings-internal___en"
    }, {
      path: "system-entities",
      component: _6d75461b,
      name: "t-tenantSlug-studio-settings-system-entities___en"
    }, {
      path: "tokens",
      component: _b1b1c218,
      name: "t-tenantSlug-studio-settings-tokens___en"
    }, {
      path: "user-groups",
      component: _226c735c,
      name: "t-tenantSlug-studio-settings-user-groups___en"
    }, {
      path: "users",
      component: _75f166f8,
      name: "t-tenantSlug-studio-settings-users___en"
    }, {
      path: "user-groups/new",
      component: _f7e1ebc0,
      name: "t-tenantSlug-studio-settings-user-groups-new___en"
    }, {
      path: "integrations/:id",
      component: _1a64c4be,
      name: "t-tenantSlug-studio-settings-integrations-id___en"
    }, {
      path: "user-groups/:id?",
      component: _7059353a,
      name: "t-tenantSlug-studio-settings-user-groups-id___en"
    }, {
      path: "user-groups/:user-group-form",
      component: _33567248,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-form___en"
    }, {
      path: "user-groups/:user-group-list-item",
      component: _fd462c98,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-list-item___en"
    }, {
      path: "users/:active-members-card",
      component: _61bae22d,
      name: "t-tenantSlug-studio-settings-users-active-members-card___en"
    }, {
      path: "users/:users-data-table-card",
      component: _7f5aed2f,
      name: "t-tenantSlug-studio-settings-users-users-data-table-card___en"
    }]
  }, {
    path: "/en/t/:tenantSlug?/studio/automations/:id?",
    component: _4ab77b12,
    children: [{
      path: "",
      component: _e02827d6,
      name: "t-tenantSlug-studio-automations-id___en"
    }, {
      path: "history",
      component: _487d3817,
      name: "t-tenantSlug-studio-automations-id-history___en"
    }]
  }, {
    path: "/en/t/:tenantSlug?/studio/data/:id",
    component: _73263086,
    name: "t-tenantSlug-studio-data-id___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/pages/:id?",
    component: _49267eda,
    name: "t-tenantSlug-studio-pages-id___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/data/:id/import",
    component: _5bf4b0ca,
    name: "t-tenantSlug-studio-data-id-import___en"
  }, {
    path: "/en/t/:tenantSlug?/studio/data/:id/schema",
    component: _7ae9d674,
    name: "t-tenantSlug-studio-data-id-schema___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId",
    component: _74e9da2c,
    name: "t-tenantSlug-m-viewId___en"
  }, {
    path: "/en/t/:tenantSlug?/p/:viewId",
    component: _442e0122,
    name: "t-tenantSlug-p-viewId___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId/r/new",
    component: _2bd7ef5d,
    name: "t-tenantSlug-m-viewId-r-new___en"
  }, {
    path: "/en/t/:tenantSlug?/m/:viewId/r/:recordId",
    component: _0adb77ab,
    name: "t-tenantSlug-m-viewId-r-recordId___en"
  }, {
    path: "/en/t/:tenantSlug?/p/:viewId/r/:recordId",
    component: _4995d06e,
    name: "t-tenantSlug-p-viewId-r-recordId___en"
  }, {
    path: "/t/:tenantSlug",
    component: _6e40da9e,
    name: "t-tenantSlug___pt"
  }, {
    path: "/t/:tenantSlug?/email-validation",
    component: _67ef5831,
    name: "t-tenantSlug-email-validation___pt"
  }, {
    path: "/t/:tenantSlug?/login",
    component: _e129af10,
    name: "t-tenantSlug-login___pt"
  }, {
    path: "/t/:tenantSlug?/m",
    component: _41e10308,
    name: "t-tenantSlug-m___pt"
  }, {
    path: "/t/:tenantSlug?/p",
    component: _c484d282,
    name: "t-tenantSlug-p___pt"
  }, {
    path: "/t/:tenantSlug?/reset-password",
    component: _33076c86,
    name: "t-tenantSlug-reset-password___pt"
  }, {
    path: "/t/:tenantSlug?/sign-up",
    component: _052d938c,
    name: "t-tenantSlug-sign-up___pt"
  }, {
    path: "/t/:tenantSlug?/studio",
    component: _e40fba86,
    name: "t-tenantSlug-studio___pt"
  }, {
    path: "/t/:tenantSlug?/m/account-configuration",
    component: _a558a600,
    name: "t-tenantSlug-m-account-configuration___pt"
  }, {
    path: "/t/:tenantSlug?/sso/saml",
    component: _6d97ecd8,
    name: "t-tenantSlug-sso-saml___pt"
  }, {
    path: "/t/:tenantSlug?/studio/data",
    component: _1d0f05d2,
    name: "t-tenantSlug-studio-data___pt"
  }, {
    path: "/t/:tenantSlug?/studio/settings",
    component: _2de36008,
    children: [{
      path: "",
      component: _08d9c08b,
      name: "t-tenantSlug-studio-settings___pt"
    }, {
      path: "app-builder",
      component: _dce7ce2a,
      name: "t-tenantSlug-studio-settings-app-builder___pt"
    }, {
      path: "authentication",
      component: _1139615c,
      name: "t-tenantSlug-studio-settings-authentication___pt"
    }, {
      path: "global-parameters-manager",
      component: _0f103230,
      name: "t-tenantSlug-studio-settings-global-parameters-manager___pt"
    }, {
      path: "integrations",
      component: _b4749e0e,
      name: "t-tenantSlug-studio-settings-integrations___pt"
    }, {
      path: "internal",
      component: _6d5ddc97,
      name: "t-tenantSlug-studio-settings-internal___pt"
    }, {
      path: "system-entities",
      component: _6d75461b,
      name: "t-tenantSlug-studio-settings-system-entities___pt"
    }, {
      path: "tokens",
      component: _b1b1c218,
      name: "t-tenantSlug-studio-settings-tokens___pt"
    }, {
      path: "user-groups",
      component: _226c735c,
      name: "t-tenantSlug-studio-settings-user-groups___pt"
    }, {
      path: "users",
      component: _75f166f8,
      name: "t-tenantSlug-studio-settings-users___pt"
    }, {
      path: "user-groups/new",
      component: _f7e1ebc0,
      name: "t-tenantSlug-studio-settings-user-groups-new___pt"
    }, {
      path: "integrations/:id",
      component: _1a64c4be,
      name: "t-tenantSlug-studio-settings-integrations-id___pt"
    }, {
      path: "user-groups/:id?",
      component: _7059353a,
      name: "t-tenantSlug-studio-settings-user-groups-id___pt"
    }, {
      path: "user-groups/:user-group-form",
      component: _33567248,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-form___pt"
    }, {
      path: "user-groups/:user-group-list-item",
      component: _fd462c98,
      name: "t-tenantSlug-studio-settings-user-groups-user-group-list-item___pt"
    }, {
      path: "users/:active-members-card",
      component: _61bae22d,
      name: "t-tenantSlug-studio-settings-users-active-members-card___pt"
    }, {
      path: "users/:users-data-table-card",
      component: _7f5aed2f,
      name: "t-tenantSlug-studio-settings-users-users-data-table-card___pt"
    }]
  }, {
    path: "/t/:tenantSlug?/studio/automations/:id?",
    component: _4ab77b12,
    children: [{
      path: "",
      component: _e02827d6,
      name: "t-tenantSlug-studio-automations-id___pt"
    }, {
      path: "history",
      component: _487d3817,
      name: "t-tenantSlug-studio-automations-id-history___pt"
    }]
  }, {
    path: "/t/:tenantSlug?/studio/data/:id",
    component: _73263086,
    name: "t-tenantSlug-studio-data-id___pt"
  }, {
    path: "/t/:tenantSlug?/studio/pages/:id?",
    component: _49267eda,
    name: "t-tenantSlug-studio-pages-id___pt"
  }, {
    path: "/t/:tenantSlug?/studio/data/:id/import",
    component: _5bf4b0ca,
    name: "t-tenantSlug-studio-data-id-import___pt"
  }, {
    path: "/t/:tenantSlug?/studio/data/:id/schema",
    component: _7ae9d674,
    name: "t-tenantSlug-studio-data-id-schema___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId",
    component: _74e9da2c,
    name: "t-tenantSlug-m-viewId___pt"
  }, {
    path: "/t/:tenantSlug?/p/:viewId",
    component: _442e0122,
    name: "t-tenantSlug-p-viewId___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId/r/new",
    component: _2bd7ef5d,
    name: "t-tenantSlug-m-viewId-r-new___pt"
  }, {
    path: "/t/:tenantSlug?/m/:viewId/r/:recordId",
    component: _0adb77ab,
    name: "t-tenantSlug-m-viewId-r-recordId___pt"
  }, {
    path: "/t/:tenantSlug?/p/:viewId/r/:recordId",
    component: _4995d06e,
    name: "t-tenantSlug-p-viewId-r-recordId___pt"
  }, {
    path: "/:locale(en)?/builder/:after(.*)",
    redirect: (to) => ({ path: `${to.params.locale}/studio/${to.params.after}` })
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
